import axios from 'axios';
import { baseUrl } from './env'

let http = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    withCredentials: false,
    headers: {
        "content-type": "application/json;charset=UTF-8",
    },
});

function apiAxios(method, url, params, response) {
    http({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
    }).then(function (res) {
        if(res.data.status == 1){
            // window.location.href = "/login";
        }else if(res.data.code == 0){
            response(res.data);
        }
    }).catch(function (err) {
        response(err);
    })
}

export default {
    get: function (url, params, response) {
        return apiAxios('GET', url, params, response)
    },
    post: function (url, params, response) {
        return apiAxios('POST', url, params, response)
    },
    put: function (url, params, response) {
        return apiAxios('PUT', url, params, response)
    },
    delete: function (url, params, response) {
        return apiAxios('DELETE', url, params, response)
    }
}
