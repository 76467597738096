var baseUrl = 'https://gateway.51beauty.com.cn'
var DEBUG = false

// if (process.env.NODE_ENV === 'development') {
//     baseUrl = 'http://192.168.1.82:9001' // 本地
//     DEBUG = false
// } else if (process.env.NODE_ENV === 'testing') {
//     baseUrl = 'https://test-gateway.51beauty.com.cn'
//     DEBUG = false
// } else if (process.env.NODE_ENV === 'production') {
//     baseUrl = 'https://gateway.51beauty.com.cn'
//     DEBUG = false
// }
export {
    baseUrl,
    DEBUG,
}
