import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
        path: '/my',
        name: 'my',
        component: () => import(/* webpackChunkName: "about" */ '../views/My.vue')
    },
    {
        path: '/introduce',
        name: 'introduce',
        component: () => import(/* webpackChunkName: "about" */ '../views/Introduce.vue')
    },
    {
        path: '/administration',
        name: 'administration',
        component: () => import(/* webpackChunkName: "about" */ '../views/Administration.vue')
    },
    {
        path: '/advertising',
        name: 'advertising',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/advertising.vue')
    },
    {
        path: '/live',
        name: 'live',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/live.vue')
    },
    {
        path: '/member',
        name: 'member',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/member.vue')
    },
    {
        path: '/digitize',
        name: 'digitize',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/digitize.vue')
    },
    {
        path: '/management',
        name: 'management',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/management.vue')
    },
    {
        path: '/scrm',
        name: 'scrm',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/product/scrm.vue')
    },
    {
        path: '/beauty',
        name: 'beauty',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/beauty.vue')
    },
    {
        path: '/oral',
        name: 'oral',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/oral.vue')
    },
    {
        path: '/eye',
        name: 'eye',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/eye.vue')
    },
    {
        path: '/hair',
        name: 'hair',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/hair.vue')
    },
    {
        path: '/traditional',
        name: 'traditional',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/traditional.vue')
    },
    {
        path: '/chronic',
        name: 'chronic',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/chronic.vue')
    },
    {
        path: '/tumour',
        name: 'tumour',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/tumour.vue')
    },
    {
        path: '/reproduce',
        name: 'reproduce',
        meta: {
            title: '云晰科技'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/plan/reproduce.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
// router.beforeEach((to, from, next) => {
//
//     // 让页面回到顶部
//
//     // chrome
//
//     document.body.scrollTop = 0;
//
//     // firefox
//
//     document.documentElement.scrollTop = 0;
//
//     // safari
//
//     window.pageYOffset = 0;
//
//     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
//
//     next()
//
// })

export default router
