import 'amfe-flexible'
import 'normalize.css'
import 'reset.css'

import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import Api from './api/index.js';
import 'animate.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(Antd,VueWechatTitle);
router.afterEach(() =>{
  document. body.scrollTop =0;
  document. documentElement. scrollTop = 0;
})

Vue.prototype.$api = Api;
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
